import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { section as sectionData } from "shared/data/section";

import { MenuItem } from "scmp-app/components/section/section-style/section-style-header-menu-item/styles";
import type { sectionStyleHeaderMenuItem$key } from "scmp-app/queries/__generated__/sectionStyleHeaderMenuItem.graphql";

type Props = {
  className?: string;
  reference: sectionStyleHeaderMenuItem$key;
};
export const SectionStyleHeaderMenuItem: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const section = useFragment(
    graphql`
      fragment sectionStyleHeaderMenuItem on Section {
        name
        entityId
      }
    `,
    reference_,
  );

  const handleRenderSection = () => {
    if (section.entityId === sectionData.style.entityId) return "Latest";
    return section.name;
  };

  return <MenuItem className={className}>{handleRenderSection()}</MenuItem>;
};

SectionStyleHeaderMenuItem.displayName = "SectionStyleHeaderMenuItem";
